.debugclass {
    background-image: url('https://lojones.github.io/assets/neural-net-1.png'); /* Replace with your image URL */
    background-size: stretch;  /* This will cover the entire div without stretching the image */
    background-repeat: repeat; /* This will prevent the image from repeating */
    background-position: center; /* This centers the image in the div */
}

.suggestionCard {
    padding: 10px;
    flex: 0 0 auto;
    margin: 4px;
    max-width: 120px;
    border: 1px solid lightgray;
    border-radius: 5px;
    color: gray;
}

#suggestioncardcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}