.lojo-links {
  margin: 0 20px;
  position: absolute;  /* Take it out of the flex flow */
  left: 35%;  /* Center horizontally */
  transform: translateX(-50%);  /* Offset by half its width */
}

.social-icons-container {
  background-color: white;
  padding: 5px;
  border-radius: 12px;
  border: 1px solid #4096ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  line-height: normal;
}

.container-label {
  font-size: 5px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.icons-row {
  display: flex;
  gap: 8px;
}

.link-icon {
  height: 20px;
  width: auto;
  vertical-align: middle;
}